export const environment = {
	production: true,
	webview: true,
	siemens: true,
	amplitude: true,
	baseUrl: 'https://api.pd01e.gcp.ford.com/api/wallbox/v1',
	tokenAPIUrl: 'https://api.pd01e.gcp.ford.com/api/token/v2/cat-with-refresh-token',
	appId: '3A8A4966-50EC-4F9B-B299-51B737547D5A',
	amplitudeApiKey: '0eb42ca51c84f5bfe35ee635860b2fc4',
	vendorCodes: { 'WEB' : ['WS1', 'WEB', 'AUT', 'SIM'], 'SIEMENS' : ['SWA', 'SIE'] },
	vendorIds: { 'WEB': 1, 'SIE': 4 }
	// include production api base url string as a property here
};
